export const makeLabel = (performance) => {
 const date = new Date(performance?.performanceDateTime);
 const time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', timeZone: 'America/Chicago' });
 return time;
};

export const makeTitle = (performance, title) => {
 const localString = new Date(performance?.performanceDateTime).toLocaleString('en-US', {
  timeZone: 'America/Chicago',
 });
 return `Learn more about the ${title} on ${localString}!`;
};
