<script setup>
 // Composables
 import { getNowSafely, convertFormat } from '@/composables/helpers/date';
 import {
  getDepartures,
  getDeparturesByDate,
  getToursByDate,
  isPerformanceOnDate,
  makeLabel,
  makeTitle,
 } from '@/composables/helpers/tours';

 // Props
 const props = defineProps({
  page: {
   type: Object,
   required: true,
  },
 });
 const { page } = toRefs(props);

 const dateClasses = {
  outer: {
   date: true,
  },
  wrapper: {
   'bg-white': true,
  },
  input: {
   h4: true,
   'text-red': true,
  },
  label: {
   h4: true,
  },
  monthButton: {
   h4: true,
  },
  yearButton: {
   h4: true,
  },
  month: {
   h6: true,
  },
  year: {
   h6: true,
  },
  yearsHeader: {
   h4: true,
  },
  day: {
   h4: true,
  },
  weekDay: {
   h6: true,
  },
  prevLabel: {
   'sr-only': true,
  },
  nextLabel: {
   'sr-only': true,
  },
 };

 // Variables
 const now = await getNowSafely();

 const startingDate = ref(null);
 const date = ref(now.value);
 const vModelDate = ref(null);

 const departure = ref(null);
 const selectedDateLabel = ref('Select your tour date');
 const selectedTimeLabel = ref('Select your departure time');

 // Computed
 const data = computed(() => {
  return page?.value?.content.data;
 });

 const hasNoTessituraSync = computed(() => {
  return !data?.value?.tessituraSync;
 });

 const sync = computed(() => {
  if (hasNoTessituraSync?.value) return data?.value?.schedule;
  return data?.value?.tessituraSync;
 });

 const prices = computed(() => {
  if (hasNoTessituraSync?.value) return data.value?.price;
  return sync?.value?.prices;
 });

 const buttonLabel = computed(() => {
  return data.value?.priceButtonLabel || 'Buy Tickets';
 });

 const performances = computed(() => {
  if (hasNoTessituraSync?.value) return sync?.value?.times;
  return sync?.value?.performances;
 });

 const currentDepartures = computed(() => {
  if (!vModelDate.value) return [];
  if (hasNoTessituraSync?.value) return getToursByDate(sync?.value, date?.value)[0] || false;
  return getDeparturesByDate(performances?.value, date?.value);
 });

 const tourDates = computed(() => {
  if (!hasNoTessituraSync?.value) return false;

  const dates = [];
  const nowString = convertFormat(now.value, 'YYYY-MM-DD');

  sync.value?.forEach((performance) => {
   const date = convertFormat(performance.date, 'YYYY-MM-DD');
   const shouldPush = !dates.includes(date) && date >= nowString;
   if (shouldPush) dates.push(date);
  });

  return dates;
 });

 const ids = computed(() => {
  const performanceId = departure?.value?.id || false;
  const productionSeasonId = departure?.value?.productionSeasonId || departure?.value?.productionSeason || false;
  return {
   performanceId,
   productionSeasonId,
  };
 });

 // Methods
 const sameDay = (date1, date2) => {
  if (typeof date1 === 'string') date1 = new Date(date1);
  if (typeof date2 === 'string') date2 = new Date(date2);

  return (
   date1.getFullYear() === date2.getFullYear() &&
   date1.getMonth() === date2.getMonth() &&
   date1.getDate() === date2.getDate()
  );
 };
 const disabledDays = (node, dateToCheck) => {
  let active = false;
  // Re-implement min-date and max-date
  if (node?.props?.minDate && dateToCheck < node?.props?.minDate) return true;
  if (node?.props?.maxDate && dateToCheck > node?.props?.maxDate) return true;

  // Disable days that are not in the performances
  // if (hasNoTessituraSync?.value) active = !isTourOnDate(date, data?.value?.schedule);
  if (hasNoTessituraSync?.value) active = !tourDates?.value?.includes(convertFormat(dateToCheck, 'YYYY-MM-DD'));
  if (performances?.value) {
   dateToCheck.setHours(12);
   active = !isPerformanceOnDate(dateToCheck, performances?.value);
  }
  return active;
 };

 const isSelected = (singleDeparture) => {
  if (!departure?.value) return false;
  return departure?.value?.id === singleDeparture?.id;
 };

 const maxDate = ref(new Date(new Date().setFullYear(new Date().getFullYear() + 2)));
 const yesterday = () => {
  const yesterday = new Date(now?.value);
  yesterday?.setDate(now?.value?.getDate() - 1);
  return yesterday;
 };

 const updateDeparture = (singleDeparture) => {
  departure.value = singleDeparture;
  updatePermalink(departure.value);
 };

 const updatePermalink = (departure) => {
  if (process.server) return;
  const params = new URLSearchParams(window.location.search);

  // Remove date param
  params.delete('date');

  // Then add new date
  if (date.value) {
   const newDate = new Date(date.value);
   newDate.setHours(12);
   params.set('date', encodeURIComponent(newDate.toISOString()));
   // }
   //  if (departure?.performanceDateTime) {
   //   const newDate = new Date(departure?.performanceDateTime).toISOString();
   //   params.set('date', encodeURIComponent(newDate));
   //  } else params.set('date', encodeURIComponent(date.value));
  }

  const newUrl = `${window.location.pathname}?${params.toString()}`;
  if (params.size > 0) history.replaceState(history.state, null, newUrl);
  else history.replaceState(history.state, null, window.location.pathname);
  if (params.size > 0) window.history.pushState(history.state, '', newUrl);
  else if (window.location.search != '') window.history.pushState(history.state, '', window.location.pathname);
 };

 const updateDateBasedOnUrl = () => {
  const { query } = useRoute();
  if (query?.date) {
   const decoded = decodeURIComponent(query.date);
   const queryTime = new Date(decoded).getTime();
   if (decoded != 'All') {
    selectedDateLabel.value = 'Your selected tour date';
    date.value = decoded ? decoded : new Date(now?.value);
    if (currentDepartures.value && currentDepartures.value?.length) {
     currentDepartures.value?.forEach((dep) => {
      const depTime = new Date(dep?.performanceDateTime).getTime();
      if (queryTime == depTime) {
       departure.value = dep;
       selectedTimeLabel.value = 'Your selected departure time';
      }
     });
    }
   }
  }
 };

 updateDateBasedOnUrl();

 const svgCalendar =
  '<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.9761 10.3193H34.0472M15.4299 10.3193H9.00781C7.90324 10.3193 7.00781 11.2148 7.00781 12.3193V41.1212C7.00781 42.2258 7.90325 43.1212 9.00781 43.1212H44.0154C45.12 43.1212 46.0154 42.2258 46.0154 41.1212V12.3193C46.0154 11.2148 45.12 10.3193 44.0154 10.3193H37.5933" stroke="black"/><rect x="15.4922" y="5.5" width="3.43268" height="8.75191" rx="1.5" stroke="black"/><rect x="34.1016" y="5.5" width="3.43268" height="8.75191" rx="1.5" stroke="black"/><path d="M7.00781 19.1846H46.0154" stroke="black"/></svg>';

 const futurePerformances = computed(() => {
  if (hasNoTessituraSync?.value) {
   const dates = sync?.value?.map((performance) => performance.date);
   if (!dates) return [];
   return dates.filter((date) => {
    const performanceDate = new Date(date);
    const nowYear = now.value.getFullYear();
    const nowMonth = now.value.getMonth();
    const nowDay = now.value.getDate();
    const nowDate = new Date(nowYear, nowMonth, nowDay);
    return performanceDate >= nowDate;
   });
  } else {
   const filteredPerformances = performances.value.filter((performance) => {
    const performanceDate = new Date(performance.performanceDateTime);
    return performanceDate > now.value;
   });

   const sortedPerformances = filteredPerformances.sort((a, b) => {
    const dateA = new Date(a.performanceDateTime);
    const dateB = new Date(b.performanceDateTime);
    return dateA - dateB;
   });

   return sortedPerformances;
  }
 });

 const nextAvailablePerformance = computed(() => {
  let nextAvailablePerformance = futurePerformances.value[0]?.performanceDateTime;
  if (hasNoTessituraSync?.value) nextAvailablePerformance = futurePerformances.value[0];
  return nextAvailablePerformance;
 });

 const setInitialDate = () => {
  if (process.server) return;
  const { query } = useRoute();
  if (query?.date) {
   const decoded = decodeURIComponent(query.date);
   if (decoded && decoded != 'All') {
    vModelDate.value = decoded ? new Date(decoded) : new Date(now?.value);
   }
  } else if (nextAvailablePerformance.value) {
   const areMatchingDays = sameDay(nextAvailablePerformance.value, now.value);
   if (areMatchingDays) vModelDate.value = new Date(nextAvailablePerformance.value);
  }
 };

 setInitialDate();

 // Watchers
 watch(vModelDate, (newVal) => {
  if (newVal) {
   const sample = new Date(newVal);
   sample.setHours(12);
   date.value = sample.toISOString();
   updatePermalink(departure.value);
  }
 });
</script>
<template>
 <section
  class="block block-tours-template-header"
  :class="{ 'has-awards': data?.awards?.length && data?.awards?.length > 0 }"
 >
  <div class="container">
   <div class="head">
    <TemplatesToursTags :data="data" />
    <h1 v-if="data?.title" v-html="data?.title" />
    <h1 v-else>Missing Page Title</h1>
   </div>
   <div class="body">
    <FragmentsCarouselsSimpleCarouselWithControls
     v-if="data?.gallery?.length"
     :carouselCards="data?.gallery"
     class="mobile"
    />
    <div class="information">
     <MessHtml v-if="page?.content?.data?.description" :html="page?.content?.data?.description" class="description" />

     <ClientOnly>
      <h2 v-if="!vModelDate" class="nextDate">
       Next available tour date:
       <span class="h6">{{
        new Date(nextAvailablePerformance).toLocaleDateString('en-US', {
         month: 'long',
         day: 'numeric',
         year: 'numeric',
        })
       }}</span>
      </h2>
      <FormKit
       type="form"
       id="tour-header-day-selector"
       v-model="startingDate"
       name="tour-header-day-selector"
       :actions="false"
      >
       <FormKit
        type="datepicker"
        id="tour-header-day-selector-date-picker"
        name="date"
        :label="selectedDateLabel"
        :format="{ date: 'long' }"
        next-icon="arrowRight"
        prev-icon="arrowLeft"
        :calendarIcon="svgCalendar"
        timezone="America/Chicago"
        :disabled-days="disabledDays"
        :min-date="now"
        :max-date="maxDate"
        v-model="vModelDate"
        :classes="dateClasses"
        picker-only
       />
       <!-- :min-date="yesterday()" -->
      </FormKit>
     </ClientOnly>

     <div class="departure-times" :class="`${hasNoTessituraSync ? 'no-sync' : ''}`">
      <ClientOnly v-if="hasNoTessituraSync || currentDepartures?.length > 0">
       <template v-if="hasNoTessituraSync">
        <strong class="h6">departure times</strong>
        <template v-if="currentDepartures?.times?.length && currentDepartures?.times?.length >= 8">
         <ul class="grid-times">
          <template v-for="index in 8">
           <li v-if="currentDepartures.times[index]?.time">
            {{ currentDepartures.times[index]?.time }}
           </li>
          </template>
         </ul>
         <div class="see-more-wrapper">
          <MessLink :href="currentDepartures.link" class="see-more link">
           <span>See More</span>
          </MessLink>
          <ProjectSvg type="arrow-top-right" />
         </div>

         <div class="price">
          <strong class="h6">Price:</strong>
          <MessHtml :html="prices" />
         </div>
         <div class="buy">
          <ProjectButton :label="buttonLabel" :link="currentDepartures.link" version="red-fill" />
         </div>
        </template>
        <template v-else>
         <ul class="grid-times">
          <template v-for="departure in currentDepartures.times">
           <li v-if="departure?.time">
            {{ departure?.time }}
           </li>
          </template>
         </ul>
         <MessLink :href="currentDepartures.link" class="see-more link">See More</MessLink>
         <div class="price">
          <strong class="h6">Price:</strong>
          <MessHtml :html="prices" />
         </div>
         <div class="buy">
          <ProjectButton :label="buttonLabel" :link="currentDepartures.link" version="red-fill" />
         </div>
        </template>
       </template>

       <template v-else>
        <strong class="h6" v-html="selectedTimeLabel"></strong>
        <ul>
         <li v-for="singleDeparture in currentDepartures">
          <ProjectButton
           :key="singleDeparture.id"
           v-if="singleDeparture"
           :disabled="singleDeparture?.expired"
           @click="updateDeparture(singleDeparture)"
           :label="makeLabel(singleDeparture)"
           :link="false"
           :title="makeTitle(singleDeparture, data?.title)"
           class="red"
           :class="`${singleDeparture?.expired ? 'expired' : 'active'} ${
            isSelected(singleDeparture) ? 'selected' : 'unselected'
           }`"
          />
         </li>
        </ul>
       </template>
       <template #fallback>
        <MessLoading class="loading" style="margin-top: 2rem" />
       </template>
      </ClientOnly>
     </div>

     <ClientOnly>
      <TemplatesToursPricing
       v-if="!hasNoTessituraSync"
       :prices="prices"
       :performanceId="departure?.id || false"
       :productionSeasonId="departure?.productionSeasonId || departure?.productionSeason || false"
      />
     </ClientOnly>
    </div>

    <div class="media desktop">
     <FragmentsCarouselsSimpleCarouselWithControls v-if="data?.gallery?.length" :carouselCards="data?.gallery" />
    </div>
   </div>

   <Awards v-if="data?.awards?.length" :awards="data?.awards" />
  </div>
 </section>
</template>

<style lang="scss">
 .block-tours-template-header {
  overflow-y: visible;
  &.has-awards {
   padding: 6rem 0 0 0;
  }

  .container {
   .head {
    h1 {
     color: #000;
     font-size: calc(84 / var(--base-fs) * 1rem);
     font-style: normal;
     font-weight: 900;
     line-height: 88.6%; /* 74.424px */
     letter-spacing: 1.26px;
     text-transform: uppercase;

     @media (max-width: 1023px) {
      font-size: calc(84 / var(--base-fs) * 1rem);
     }

     @media (max-width: 767px) {
      font-size: calc(70 / var(--base-fs) * 1rem);
     }

     @media (max-width: 480px) {
      font-size: calc(40 / var(--base-fs) * 1rem);
     }
    }
   }

   .body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;

    .mobile {
     display: none;
    }

    @media (min-width: 860px) {
     flex-direction: row;
     gap: 1.5rem;
    }

    .information {
     width: min(555px, 100%);

     .nextDate {
      color: var(--black);
      font-size: 20px;
      font-style: normal;
      font-weight: 350;
      line-height: 135.6%;
      letter-spacing: 0.3px;
      text-transform: unset;
      margin-top: 1.5rem;

      .h6 {
       color: var(--red);
      }
     }

     .formkit-form {
      .formkit-outer[data-type='datepicker'] {
       .formkit-calendar-icon {
        top: 11px;
        width: 32px;
        height: 32px;
        align-items: center;

        svg {
         width: 100%;
         height: 20px;
         margin-left: -1px;
         margin-top: -1px;
         path {
          fill: transparent;
         }
         path,
         rect {
          stroke: var(--white);
          stroke-width: 2px;
         }
        }
       }

       .formkit-panel-wrapper {
        z-index: 999;
        left: -16px;
        @media (min-width: $phone) {
         left: 0;
        }

        .h4 {
         margin-bottom: 0;
        }

        .formkit-day-cell {
         &[data-disabled='true'] {
          text-decoration: line-through;
         }
        }
       }
      }

      #tour-header-day-selector-date-picker_inner {
       z-index: 4;
      }
     }

     .description {
      color: var(--black);
      /* Body/Body 1 */
      font-size: 20px;
      font-style: normal;
      font-weight: 350;
      line-height: 135.6%; /* 27.12px */
      letter-spacing: 0.3px;
     }

     #tour-header-day-selector {
      margin-top: 2rem;

      label {
       color: #000;

       /* Headline/H6 */
       font-size: 14px;
       font-style: normal;
       font-weight: 900;
       line-height: 25px; /* 178.571% */
       letter-spacing: 1.12px;
       text-transform: uppercase;
      }

      #tour-header-day-selector-date-picker_inner {
       justify-content: flex-start;
       width: min-content;

       input {
        cursor: pointer;
        // border: 1px solid var(--gray-300);
        padding: 0 1rem 1rem 1rem;
        color: var(--red, #c00);
        /* Headline/H4 */
        font-family: Avenir;
        font-size: 26px;
        font-style: normal;
        font-weight: 900;
        line-height: 114.6%; /* 29.796px */
        letter-spacing: 0.39px;
        text-transform: uppercase;
        text-align: left;
        margin-right: 0;
        padding-right: 2rem;
        padding-left: 0;
       }

       #tour-header-day-selector-date-picker_listbox_button {
        margin-left: -1rem;
        @media (max-width: $phone) {
         margin-left: -3rem;
        }
       }
      }
     }

     .departure-times {
      &.no-sync {
       .price {
        margin-top: 2rem;
       }

       .buy {
        margin-top: 2rem;
       }
      }
      .mess-loading {
       margin-left: 0;
       --color-1: var(--red);
       --color-2: var(--gray-600);
       --color-3: var(--black);
      }
      ul {
       margin-top: 0.5rem;
       width: 100%;
       display: flex;
       flex-wrap: wrap;
       gap: 1rem;

       &.grid-times {
        max-width: 22rem;
        gap: 0.5rem;
       }

       li {
        .selected {
         background: var(--red);
         color: white;
        }

        button:disabled {
         cursor: not-allowed;
         background-color: var(--gray-300);
         border-color: var(--gray-300);
         color: var(--gray-700);
        }
       }
      }

      .see-more-wrapper {
       display: flex;
       align-items: center;
       gap: 0.5rem;
       svg {
        width: 1rem;
       }
      }

      .see-more {
       font-size: 14px;
       font-style: normal;
       font-weight: 900;
       line-height: 25px; /* 178.571% */
       letter-spacing: 1.12px;
       text-transform: uppercase;
       margin-top: 0.25rem;
       display: inline-block;
       width: fit-content;
      }
     }
    }

    .media {
     width: min(785px, 100%);

     .inner-container {
      max-width: unset;
     }

     span {
      display: block;
      width: 100%;
      aspect-ratio: 1;
      background-color: #f0f0f0;
     }
    }
   }

   .block-awards.list {
    margin-top: 4rem;

    @media (min-width: 600px) {
     justify-content: center;
    }
   }
  }

  @media (max-width: $mobile) {
   .container {
    .body {
     .mobile {
      display: block;
      max-width: 100%;
     }
    }

    .desktop {
     display: none;
    }
   }
  }
 }
</style>
